import { conCapacitacion } from "../../Models";
import { Col, Row, FormGroup, Button } from "react-bootstrap";
import { ContrySelector, StateSelector } from "../../../../Components/Contry";
import { FieldControlText } from "../../../../Components/Material/FieldControl";
import TLFN from "@/Capacitacion/Paneles/Suscribirse/Telefono";
import { Box } from "@mui/material";
import { SelectBanderaFronTel } from "@/Components/Contry/ContrySelector";
import "./Telefono.css";

export default class Telefono extends TLFN {
    render = () => {
        const {
            state: { pais, correo, phone, estado, telefono, nombre, ciudad, error, suscrito },
            setState, setPais, send
        } = this;
        return suscrito ? <Row className="boton mr-3 ml-1 p-2"><Button block><b>Gracias por suscribirte</b></Button></Row> : <Row className="mt-4">
            <Col as={FormGroup} md={12}><h2 className="text-info"><b>Suscríbete para recibir información de las próximas fechas:</b></h2></Col>
            <Col className="input" as={FormGroup} lg={12}><FieldControlText size="small" input={{ maxLength: 200, name: 'name' }} error={error} update="nombre" setData={setState} label="Nombre*" value={nombre} /></Col>
            <Col className="input" as={FormGroup} lg={8}><FieldControlText size="small" input={{ maxLength: 300, name: 'email' }} error={error} update="correo" setData={setState} label="Correo Electronico*" value={correo} /></Col>
            <Col className="input" as={FormGroup} lg={4}><ContrySelector size="small" error={error.pais} defaultValue={pais} change={setPais} /></Col>
            {phone ? <>
                <Col as={FormGroup} lg={5}>
                    <Box className="telefono" sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <SelectBanderaFronTel value={phone} change={phone => setState({ phone })} />
                        <span>+{phone ? phone : 0}-</span>
                        <FieldControlText size="small" mask="999999999999" update="telefono" setData={setState} label="Whatsapp*" value={telefono} />
                    </Box>
                </Col>
                <Col className="input" as={FormGroup} lg={7}><StateSelector size="small" error={error.estado} pais={pais} defaultValue={estado} change={estado => setState({ estado })} /></Col>
            </> : <></>}
            {estado !== '' ? <>
                <Col className="input" as={FormGroup} lg={12}>
                    <FieldControlText size="small" input={{ maxLength: 100 }} update="ciudad" error={error} setData={setState} label="Ciudad*" value={ciudad} />
                </Col>
            </> : <></>}
            <Col className='boton m-2 p-0' lg={12}><Button onClick={() => send()} block variant="primary"><b>¡Inicia ahora!</b></Button></Col>
        </Row>
    }
    send = () => {
        const { pais, correo, estado, ciudad, telefono, nombre, phone } = this.state;
        const { id } = this.props;
        if (this.getValidation())
            conCapacitacion().Suscribir(id, { pais, correo, estado, ciudad, telefono: `+${phone}-${telefono}`, nombre },
                () => this.setState({ suscrito: true }), error => this.setState({ error }))
    }
}